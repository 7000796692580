<template>
  <v-app>
    <v-app-bar app color="primary" dark v-if="isLoggedIn">

      <div class="d-flex align-center">
        <v-btn icon @click.stop="mini = !mini" >
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </div>

      <v-spacer></v-spacer>
    </v-app-bar>
    <Menu :mini="mini" :user="user" v-if="isLoggedIn"></Menu>
    <v-main class="blue lighten-5">
      <v-container>
      <router-view/>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Menu from './components/Menu.vue';

export default {
  name: 'App',
  components: {
    Menu
  },
  data: () => ({
        mini: true
      }
    ),
  computed: {
    isLoggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    user() {
      return this.$store.state.auth.user
    }
  }
};
</script>
<style>