<template>
  <v-card
    :loading="loading"
    class="mx-auto my-12"
    max-width="374"
  >
    <template slot="progress">
      <v-progress-linear color="primary" height="10" indeterminate></v-progress-linear>
    </template>

    <v-img
      :aspect-ratio="1/1"
      src="../assets/logo.png"
      width="150"
    ></v-img>

    <v-card-title>Connexion</v-card-title>

    <v-divider class="mx-4"></v-divider>

    <validation-observer ref="observer" v-slot="{ invalid }">
    <form @submit.prevent="submit">
    <v-card-text>
      <validation-provider v-slot="{ errors }" name="Identifiant" rules="required|min:3">
        <v-text-field
          v-model="user.username"
          :error-messages="errors"
          label="Identifiant"
          required
        ></v-text-field>
      </validation-provider>
      <validation-provider v-slot="{ errors }" name="Mot de passe" rules="required|min:3">
        <v-text-field v-model="user.password" type="password" :error-messages="errors" label="Mot de passe" required></v-text-field>
      </validation-provider>


  
    </v-card-text>

    <v-card-actions>
      <v-btn color="primary lighten-2" type="submit" text :disabled="invalid">
        Connecter
      </v-btn>
    </v-card-actions>    
    </form>
    </validation-observer>
  </v-card>
</template>

<script>
import { setInteractionMode } from 'vee-validate'
import User from '../models/user';
setInteractionMode('eager')

export default {
    name:"Login",
    data: () => ({
      loading: false,
      selection: 1,
    user: new User('', '',''),
    message: ''
    }),
    computed: {
    isLoggedIn() {
        return this.$store.state.auth.status.loggedIn;
        }
    },
    methods: {
      submit () {
        const res= this.$refs.observer.validate() 
        if(res) {
            this.doLogin()
        }
      }
      ,
    doLogin() {
        this.loading = true;
        this.$store.dispatch('login', this.user).then(
            () => {
                this.$router.push('/home');
            },
            error => {
                this.loading = false;
                if(error.response.status == 403) {
                  this.message = 'Login ou mot de passe incorrect'
                }
                else {
                  this.message = 'Erreur'
                }
            }
        )
    },
    },
  }
</script>

<style>

</style>