<template>
  <v-navigation-drawer v-model="drawer" :mini-variant.sync="mini" permanent app dark>
      <v-list-item class="px-2">
        <v-list-item-avatar>
          <v-icon dark>
            mdi-account-circle
          </v-icon>
        </v-list-item-avatar>

        <v-list-item-title>{{user.prenom}} {{user.nom}}</v-list-item-title>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense nav>     
        <v-list-item v-for="child in items.filter(x => x.items == undefined)" :key="child.title" link :to="child.to">
            <v-list-item-icon>
            <v-icon>{{ child.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ child.title }}</v-list-item-title>
          </v-list-item-content> 
        </v-list-item>

        <v-list-group v-for="item in items.filter(x => x.items != undefined)" :key="item.title" v-model="item.active" :prepend-icon="item.icon" no-action>
          <template v-slot:activator v-if="item.items">
            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item v-for="child in item.items" :key="child.title" link :to="child.to">
            <v-list-item-icon>
              <v-icon>{{ child.icon }}</v-icon>
           </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title v-text="child.title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>

    </v-navigation-drawer>
</template>

<script>
export default {
    name:"Menu",
    props:['mini','user'],
    data: () => ({
        drawer: true,
        items: [
        {
          icon: 'mdi-view-dashboard',
          title: 'Dashboard',
          to:'/dashboard'
        },
        {
          icon: 'mdi-account-multiple',
          title: 'Clients',
          to:'/clients'
        },
        {
          icon: 'mdi-pencil',
          title: 'Ecritures',
          to:'/ecritures'
        },
        {
          icon: 'mdi-checkbox-marked-outline',
          title: 'Pointage',
          to:'/pointage'
        },
        {
          icon: 'mdi-cog-outline',
          title: 'Administration',
          to:'/admin',
          items: [
            { title: 'Banques', icon: "mdi-bank", to:'/banques' },
            { title: 'Postes' , to:'/postes'},
            { title: 'Structures', to:'/structures' },
            { title: 'Statuts', to:'/statuts' },
            { title: 'Gestion des droits', icon: "mdi-shield", to:'/droits' }
          ]
        }
      ]
      }
    ),
}
</script>

<style>

</style>